@import-normalize;

* {
  box-sizing: border-box;
  font-family: "Open Sans";
}

html, body {
  margin: 0;
  padding: 0;
}


ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  align-items: center;
  background-color: #ECECEC;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 5px;
  padding: 10px;
  user-select: none;
}

@import "./containers/full-page-wrapper.scss";
@import "./containers/wrapper.scss";

@import "./components/pop-box.scss";
@import "./components/box.scss";

.driver-row .action-icon-visiblity {
  display:none;
}
.driver-row:hover .action-icon-visiblity{
  display:block;
}

.delete-action .delete-action-tooltip{
  display: none;
}

.delete-action:hover .delete-action-tooltip{
  display: block;
}
.edit-action .edit-action-tooltip{
  display: none;
}
.edit-action:hover .edit-action-tooltip{
  display: block;
}