.pop-box {
  width: 384px;
  padding: 35px;

  &--wide {
    width: 770px;
  }
  
  img {
    width: 200px;
  }
}