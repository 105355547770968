.box {
  height: 40px;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;

  &--with-bg {    
    background-color: #ECECEC;
    text-align: center;
  }

  &--red {
    color: #C50015;
  }
}